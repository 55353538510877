import { Component } from '@angular/core';
import { AppState } from 'src/app/state/app.state';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { asArray, SupplierOfferStatus, Status, UserStatus, asGuidArray, OfferType } from 'src/app/enum';
import { OffersSearchDataService } from 'src/app/_services';
import { TranslateService } from '@ngx-translate/core';
import { SellerOffersListState } from '../offers-list.state';
import { OffersSearchCardBase } from './offers-search.card';

@Component({
  selector: "seller-offers-search-card",
  templateUrl: './seller-offers-search.card.html'
})
export class SellerOffersSearchCardComponent extends OffersSearchCardBase {

  public constructor(
    appState: AppState,
    state: SellerOffersListState,
    public offersSearchDataService: OffersSearchDataService,
    private _translateService: TranslateService,
    route: ActivatedRoute
  ) {
    super(appState, state, offersSearchDataService, route);

    this.offerTypeOptions = asGuidArray(OfferType) || [];
    this.statusOptions = asArray(SupplierOfferStatus) || [];
    this.retailerOptions = this.formData?.retailers || [];
    this.recipientsOptions = this._getSupplierRecipientOptions(this.retailerOptions);

    this._updateSupplierRecipientsOptions(this.searchData.value.retailers);

    this._updateSupplierUserOptions(this.formData?.users, this.searchData?.get('showInactiveUsers').value);
    this.searchData?.get('retailers')?.valueChanges
      .subscribe(val => {
        this.searchData.get('recipients').patchValue([]);
        this._updateSupplierRecipientsOptions(val);
      });
    this.searchData?.get('showInactiveUsers')?.valueChanges
      .subscribe((showInactiveUsers: boolean) => {
        let users = this.formData?.users;
        this._updateSupplierUserOptions(users, showInactiveUsers)
      });

    this._translateService.onLangChange.subscribe(_ => {
      let users = this.formData?.users;
      this._updateSupplierUserOptions(users, this.searchData?.get('showInactiveUsers').value);
    });
  }

  public get searchData(): FormGroup {
    return this.offersSearchDataService.sellerSearchData;
  }
  
  private _updateSupplierRecipientsOptions(selectedRetailers: string[]): void {
    let selectedRetailerIds = selectedRetailers;
    this.recipientsOptions = selectedRetailerIds?.length > 0 ? 
      this._getSupplierRecipientOptions(this.retailerOptions.filter(x => selectedRetailerIds.includes(x.id)))
      : this._getSupplierRecipientOptions(this.retailerOptions)
  }

  private _getSupplierRecipientOptions(retailerOptions: any[]): any[] {
    if (!retailerOptions) return [];

    return retailerOptions
      .map(t => t.purchaseManagers?.map(m => ({ ...m, displayName: m.name || m.email, tenantName: t.name, userId: m.userId })) || [])
      .reduce((arr, e) => arr.concat(e), []);
  }

  private _updateSupplierUserOptions(users: any[], showInactive: boolean): void {

    if (!users) {
      this.userOptions = [];
      return;
    }

    this.userOptions = (showInactive ? users : users.filter(x => x.status === Status.Active))
    .map(x => {
      const result = { ...x };
      if (result.status != UserStatus.Active) {
        result.name += ` (${this._translateService.instant('cards.offers.offers_supplier_search.label.inactive')})`;
      }
      return result;
    });
  }

  override _serializeSearchData() {
    return this.offersSearchDataService.serializeSellerSearchData();
  }
}