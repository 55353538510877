import { Component, Injector, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IGs1Segment, IProductExtraParameters, IClvDto } from '../product-info/product-info.model';
import { ProductCardBase } from '../product-card-base';
import { Observable, Subscription } from 'rxjs';
import { formUtil, keep } from 'src/app/util/form-util';
import { UserAction, ProductCardSection } from 'src/app/enum';
import { ProductValidity } from 'src/app/_services';
import { LOCAL_STORAGE_KEY } from 'src/app/config';

@Component({
  selector: 'product-custom-card',
  templateUrl: './product-custom.card.html',
  styleUrls: ['./product-custom.card.scss']
})
export class ProductCustomCard extends ProductCardBase<IProductExtraParameters> {

  public UserAction = UserAction;

  public translationsPath = 'cards.products.product_description_fields';

  public get title() { return this.translationsPath + '.title'; }

  public cardSection = ProductCardSection.ExtraParameters;

  public selectedTags: any[];
  public tagsOptions: IClvDto[];

  public isExpandedKey = LOCAL_STORAGE_KEY.PRODUCT_CUSTOM_CARD_OPEN;

  private _changesSubscription: Subscription;

  public hasAnyValue = false;

  @Input()
  public productGs1Segment: IGs1Segment;

  public productGs1SegmentId = () => {
    let res = [];

    if (this.productGs1Segment) {
      res.push(this.productGs1Segment.id);
    }

    return res;
  }

  constructor(injector: Injector, public productValidity: ProductValidity) {
    super(injector, 'extraParameters', 'productExtraParametersChanged');
  }

  protected _getNormalizedFormData(data: IProductExtraParameters) {
    return formUtil.transformData(data, {
      parameter1: keep,
      parameter2: keep,
      parameter3: keep,
      parameter4: keep,
      parameter5: keep,
      parameter6: keep,
      parameter7: keep,
      parameter8: keep,
      parameter9: keep,
      parameter10: keep,
      tags: keep
    });
  }

  protected _createFormGroup(data: IProductExtraParameters): FormGroup {
    this.selectedTags = data.tags;
    return formUtil.createFormGroup(this._getNormalizedFormData(data));
  }

  protected _createSaveRequest() {
    const form = this.form.value;
    return {
      parameter1: form.parameter1,
      parameter2: form.parameter2,
      parameter3: form.parameter3,
      parameter4: form.parameter4,
      parameter5: form.parameter5,
      parameter6: form.parameter6,
      parameter7: form.parameter7,
      parameter8: form.parameter8,
      parameter9: form.parameter9,
      parameter10: form.parameter10,
      tags: form.tags?.map(t => t.id)
    };
  }

  protected _saveInternal(req: any): Observable<any> {
    return this._productService.saveExtraParameters(this.productId, req);
  }

  protected get successMessage(): string {
    return this.translationsPath + '.save_successful';
  }

  public get showContent(): boolean {
    return this.edit || this.hasAnyValue;
  }

  private _getHasAnyValue(form = this.form.value) {
    const { tags } = form;

    this.hasAnyValue = false;

    if (tags?.length > 0) {
      this.hasAnyValue = true;
    } else {
      for (let i = 1; i <= 10; i++) {
        const hasValue = form[`parameter${i}`] ? true : false;
        if (hasValue) {
          this.hasAnyValue = true;
          break;
        }
      }
    }
  }

  private _setTagsOptions() {
    this.tagsOptions = this._formData.productTags
      .filter(x =>
        x.gs1Segments.length == 0 ||
        x.gs1Segments.some(q => this.productGs1SegmentId().includes(q))
      );
  }

  ngOnInit() {
    super.ngOnInit();
    this._getHasAnyValue();
    this._changesSubscription = this.editing.subscribe(x => this._getHasAnyValue());
  }

  ngOnChanges(changes: any) {
    if (changes.productGs1Segment) {
      this._setTagsOptions();
    }
  }

  ngOnDestroy() {
    super.ngOnInit();
    if (this._changesSubscription) {
      this._changesSubscription.unsubscribe();
    }
  }
}
