import { Component, HostBinding } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "../../../_services/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/state/app.state";
import { NavigatorState } from "src/app/state/navigator.state";
import { LocaleService, UserService, ILanguage } from "../../../_services";
import { LxmAppModule, UserAction } from "src/app/enum";
import { LINKS, LOCAL_STORAGE_KEY, RETAILER_ID } from "src/app/config";
import { LocalStorage } from "src/app/_helpers";
import { appSettings } from "src/app/app.settings";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { AppLanguage } from "src/app/enum/app-language";

@Component({
  selector: "navigator",
  templateUrl: "./navigator.component.html",
  styleUrls: ["./navigator.scss"]
})
export class NavigatorComponent {
  @HostBinding("class.navigator-collapsed") public get collapsed() {
    return this.navigator.collapsed;
  }

  @HostBinding("class.item-fullscreen") public get isAnyItemFullscreen() {
    return this.navigator.isAnyItemFullscreen;
  }

  public UserAction = UserAction;
  public LxmAppModule = LxmAppModule;

  public form: FormGroup;

  public showTenantsFilter = false;

  constructor(
    private _router: Router,
    public appState: AppState,
    public state: AppState,
    public locale: LocaleService,
    public navigator: NavigatorState,
    public translate: TranslateService,
    private _authenticationService: AuthenticationService,
    private _userService: UserService,
    private _translateService: TranslateService
  ) {
    navigator.hasBuyerPimExtensions = appState.hasModule(
      LxmAppModule.BuyerPimExtensions
    );
  }

  public tenants = [];

  public get currentView() {
    return this.navigator.currentView;
  }

  public get version() {
    return appSettings?.version;
  }

  public get tenantId() {
    return this._authenticationService.currentTenantId.value;
  }

  public get showProductReactiveLinks() {
    if (this.collapsed) return false;
    return (
      this.navigator.showProductReactiveLinks &&
      this._router.isActive("products", {
        paths: "subset",
        queryParams: "ignored",
        fragment: "exact",
        matrixParams: "ignored"
      })
    );
  }

  public get showMarketReactiveLinks() {
    if (this.collapsed) return false;
    return (
      this.navigator.showProductReactiveLinks &&
      this._router.isActive("market", {
        paths: "subset",
        queryParams: "ignored",
        fragment: "exact",
        matrixParams: "ignored"
      })
    );
  }

  public languageValueCompare(v1: ILanguage, v2: ILanguage) {
    return v1.id === v2.id;
  }

  public get languageOptions() {
    const uiLanguages = this.appState.userSettings?.uiLanguages;
    if (uiLanguages?.length > 0) {
      return uiLanguages;
    }
    return this.locale.languageOptions;
  }

  public getLanguageIcon(languageId: AppLanguage) {
    switch (languageId) {
      case AppLanguage.Estonian:
        return "est";
      case AppLanguage.English:
        return "gbr";
      case AppLanguage.Tallink: // TAL/Tallink
        return "tallink";
      case AppLanguage.German:
        return "deu";
      default:
        return "global";
    }
  }

  public toggleView() {
    this.currentView === 0
      ? (this.navigator.currentView = 1)
      : (this.navigator.currentView = 0);
  }

  public collapse() {
    let updatedState = !this.navigator.collapsed;
    this.navigator.collapsed = updatedState;
    LocalStorage.set(LOCAL_STORAGE_KEY.NAVIGATOR_OPEN, updatedState);
    setTimeout(_ => {
      window.dispatchEvent(new Event("resize"));
    });
  }

  public get instructionsLink() {
    const lang = this._translateService.currentLang;

    switch (this.tenantId) {
      case RETAILER_ID.SELVER:
        const hasRight = this._authenticationService.hasRight([
          UserAction.ViewClvs
        ]);
        return hasRight ? LINKS.SELVER_MANUAL : null;
      default:
        switch (lang) {
          case "et":
            return LINKS.LXM_MANUAL_ET;
          default:
            return LINKS.LXM_MANUAL_EN;
        }
    }
  }

  public get isSelver() {
    return this.tenantId === RETAILER_ID.SELVER;
  }

  public get userManagementLink() {
    const lang = this._translateService.currentLang;

    switch (this.tenantId) {
      case RETAILER_ID.SELVER:
        return null;
      default:
        switch (lang) {
          case "et":
            return LINKS.USER_MANAGEMENT_ET;
          default:
            return LINKS.USER_MANAGEMENT_EN;
        }
    }
  }

  public get pujuLink() {
    switch (this.tenantId) {
      case RETAILER_ID.SELVER:
        return LINKS.SELVER_PUJU;
      default:
        return null;
    }
  }

  public get supplierFrVgInstructionsLink() {
    const lang = this._translateService.currentLang;

    switch (lang) {
      case "et":
        return LINKS.SUPPLIER_FRVG_ET;
      default:
        return LINKS.SUPPLIER_FRVG_EN;
    }
  }

  public get packagingDataInstructionsLink() {
    const lang = this._translateService.currentLang;

    switch (lang) {
      case "et":
        return LINKS.PACKAGING_DATA_ET;
      default:
        return LINKS.PACKAGING_DATA_EN;
    }
  }

  public get ordersInstructionsLink() {
    switch (this.tenantId) {
      case RETAILER_ID.SELVER:
        return LINKS.SELVER_ORDERS;
      default:
        return null;
    }
  }

  public get contactLink() {
    switch (this.tenantId) {
      case RETAILER_ID.SELVER:
        return LINKS.SELVER_CONTACT;
      default:
        return LINKS.LXM_CONTATCT;
    }
  }

  public navigateQuickAction(url, actions?: string[]) {
    this._router
      .navigate([url], { queryParams: actions ? { action: actions } : {} })
      .then(() => {
        this.navigator.currentView = 0;
      });
  }

  public changeLanguage(selection: ILanguage) {
    this.locale.setLanguage(selection);

    this._userService.setPreferredLanguage(selection.id).subscribe();
  }

  public logout() {
    this._authenticationService.logout();
    this._router.navigate(["/logout"]);
  }

  public switchTenant(tenantId: string) {
    this._authenticationService.switchTenant(tenantId);
  }

  public get adminLinkUrl() {
    if (this.appState.hasRight([UserAction.ManageSettings])) {
      return "/lxm-settings/companyprofile";
    }

    if (this.appState.hasRight([UserAction.ViewUsers])) {
      return "/lxm-settings/users";
    }

    return "";
  }

  ngOnInit() {
    const prop = "name";
    const tenants = this.state.userSettings?.tenants?.sort((a, b) =>
      a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
    );
    this.form = new FormGroup({
      tenant: new FormControl(this.tenantId)
    });

    this.tenants = tenants;
    this.showTenantsFilter = tenants?.length > 10;
  }
}
