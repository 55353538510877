import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ITenantQuota } from 'src/app/models/settings/tenant-quota';
import { SettingsService } from 'src/app/_services';
import { LxmMessage, LxmDialog } from 'src/app/_helpers';
import { TermsDialogComponent } from 'src/app/components/_partials/terms-and-conditions/terms.dialog';
import { appSettings } from 'src/app/app.settings';
import { LINKS } from 'src/app/config';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "package-info-card",
  templateUrl: './package-info.card.html',
  styleUrls: ['./package-info.scss']
})
export class PackageInfoCard {

  public requestedPlanId: number;
  public requestedPlanAnnual: boolean;

  public currentPlan: any;
  public currentAnnual: boolean;

  public activeTo: Date;
  public productsActive: number;
  public productCapacity: number;
  public savePackageLoading = false;

  public packageInfo = {
    activePackageId: 0,
    packages: [
      { id: 7, type: 'XXS', price: { annual: 15 } },
      { id: 2, type: 'XS', price: { annual: 45, monthly: 49 } },
      { id: 3, type: 'S', price: { annual: 137, monthly: 149 } },
      { id: 6, type: 'M', price: { annual: 229, monthly: 249 } },
      { id: 4, type: 'L', price: { annual: 320, monthly: 349 } },
      { id: 5, type: 'XL', price: { annual: 687, monthly: 749 } },
    ]
  };

  public get packages() {
    return this.packageInfo.packages.filter(x => x.price[this.form.value.annual ? 'annual' : 'monthly']);
  }

  public get productsActivePercent() {
    return this.productCapacity
      ? this.productsActive / this.productCapacity * 100
      : 0;
  }

  public form: FormGroup;

  constructor(
    route: ActivatedRoute,
    private _message: LxmMessage,
    private _dialog: LxmDialog,
    private _translateService: TranslateService,
    private _settingsService: SettingsService) {

    const tenantQuota = route.snapshot.data.packageData.quota as ITenantQuota;
    this.productsActive = tenantQuota.activeProductsCount;
    this.productCapacity = tenantQuota.maxActiveProducts;
    this.activeTo = tenantQuota.activeTo;

    this.currentPlan = this.packageInfo.packages.find(x => x.id === tenantQuota.planId);
    this.currentAnnual = tenantQuota.annual;

    this.requestedPlanId = route.snapshot.data.packageData.requestedPlan % 100;
    this.requestedPlanAnnual = route.snapshot.data.packageData.requestedPlan > 100;

    if (this.requestedPlanId) {
      this.form = new FormGroup({
        activePackageId: new FormControl(this.requestedPlanId),
        annual: new FormControl(this.requestedPlanAnnual)
      });
    } else {
      this.form = new FormGroup({
        activePackageId: new FormControl(tenantQuota.planId),
        annual: new FormControl(tenantQuota.annual)
      });
    }
  }

  public savePackage() {
    this.savePackageLoading = true;

    const f = this.form.value;
    const req = {
      plan: f.activePackageId,
      annual: f.annual
    };

    this._settingsService.updatePackage(req)
      .result(this.form, (res: { newQuota: ITenantQuota, viaStripe: boolean, viaNotification: boolean, requestedPlan: number }) => {

        if (res.viaStripe) {

          this._message.ok({
            message: 'cards.lexi_market_settings.lxm_settings_subscription.label.plan_changed_successfully'
          });

          this.currentPlan = res.requestedPlan % 100;
          this.currentAnnual = res.requestedPlan > 100;

          this.productCapacity = res.newQuota.maxActiveProducts;
          this.activeTo = res.newQuota.activeTo;
        } else if (res.viaNotification) {

          this.requestedPlanId = res.requestedPlan % 100;
          this.requestedPlanAnnual = res.requestedPlan > 100;

          this._dialog.confirm({
            title: 'cards.lexi_market_settings.lxm_settings_subscription.label.plan_change_request_sent_successfully_title',
            message: 'cards.lexi_market_settings.lxm_settings_subscription.label.plan_change_request_sent_successfully_message',
            yes: 'global.ok',
            showNo: false
          }, () => { });
        }

        this.savePackageLoading = false;
      }, (error) => {

        this._message.error({
          messages: error.validationResults['plan'].map(x => x.value)
        });

        this.savePackageLoading = false;
      }
    );
  }

  public cancelSubscription() {

    this._dialog.confirm({
      message: 'cards.lexi_market_settings.lxm_settings_subscription.label.cancel_subscription_text',
      title: 'cards.lexi_market_settings.lxm_settings_subscription.label.cancel_subscription_confirm_title'
    }, () => {
      this._settingsService.cancelSubscription()
        .result(this.form, (res: ITenantQuota) => {
          this.activeTo = res.activeTo;

          this._message.ok({
            message: 'cards.lexi_market_settings.lxm_settings_subscription.label.subscription_cancelled_successfully'
          });

        });
    });
  }

  public get termsLink() {
    const lang = this._translateService.currentLang;

    switch(lang) {
      case 'et':
        return LINKS.LXM_TERMS_ET;
      default:
        return LINKS.LXM_TERMS_EN;
    }
  }

  // public openTerms() {
  //   this._dialog.open(TermsDialogComponent, {
  //     ...appSettings.DIALOG_SIZES.M
  //   });
  // }

}

export interface IPackageInfo {
  id: any;
  type: string;
  isActive: boolean;
  validTo: any;
}
