import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { DialogComponent } from 'src/app/components';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { asGuidArray, OfferType, UserAction } from 'src/app/enum';
import { IMPORTABLE_OFFER_TYPES } from 'src/app/config';
import { ExportService, FileService, OfferService } from 'src/app/_services';
import { HttpEventType } from '@angular/common/http';
import { IErrorValue, LxmMessage } from 'src/app/_helpers';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'offer-import-dialog',
  host: { 'class': 'dialog' },
  templateUrl: './import-offer.dialog.html',
  styleUrls: ['./import-offer.dialog.scss']
})
export class OfferImportDialogComponent implements AfterViewInit {
  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public form: FormGroup;

  public UserAction = UserAction;

  public supplierOptions = [];
  public supplierContactOptions = [];
  public offerTypes = [];
  public currencyOptions = [];
  public purchaseManagerOptions = [];

  public files: any = [];
  public fileId: string;

  public isFileLoading = false;
  public isProcessing = false;
  public invalidProducts = false;

  public validationResults:  { [id: string]: IErrorValue[] };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<OfferImportDialogComponent>,
    private _fileService: FileService,
    private _offerService: OfferService,
    private _message: LxmMessage,
    private _router: Router,
    private _exportService: ExportService) {

    const offerTypes = asGuidArray(OfferType);
    this.offerTypes = offerTypes?.filter((x: any) => IMPORTABLE_OFFER_TYPES.includes(x.id));

    this.form = new FormGroup({
      supplier: new FormControl(),
      supplierContact: new FormControl(),
      offerType: new FormControl(this.offerTypes[0]),
      currency: new FormControl(),
      purchaseManager: new FormControl()
    });

    this.form.get('supplier').valueChanges
      .subscribe(val => {
        this.form.get('supplierContact').setValue(null);
        this.supplierContactOptions = val?.contacts || [];
      });
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        this.currencyOptions = this.data?.formData?.salesCurrencies || [];
        this.form.get("currency").setValue(this.currencyOptions[0]);

        this.supplierOptions = this.data?.formData?.suppliers || [];
        this.purchaseManagerOptions = this.data?.formData?.purchaseManagers || [];
      });

  }

  public onAddNewSupplierClick() {
    this.closeDialog();
    this._router.navigate(['/suppliers/new']);
  }

  public closeSuppliersSelectSubject: Subject<void> = new Subject<void>();

  public closeDialog() {
    this.closeSuppliersSelectSubject.next();
    this.deleteFile(0);
    this.dialogRef.close(null);
  }

  public currencyLabel(item: any) {
    return `${item.value} (${item.code})`;
  }

  public supplierLabel(item: any) {
    var label = item.name;
    if (item.regNo) {
      label += ` (${item.regNo})`;
    }
    return label;
  }

  public userLabel(item: any) {
    var label = item.name;
    if (item.role) {
      label += ` - ${item.role}`;
    }
    if (item.email) {
      label += ` (${item.email})`;
    }
    return label;
  }

  public uploadFile(fileList: FileList) {
    const file = fileList[0];

    const dto = {
      id: '',
      name: file.name,
      progress: 0,
      customText: null
    };
    this.files.push(dto);

    const formData = new FormData();
    formData.append('file', file);

    this._fileService.upload(formData)
      .subscribe(async event => {
        switch (event.type) {

          case HttpEventType.UploadProgress:
            dto.progress = Math.round(100 * event.loaded / event.total);
            this.isFileLoading = true;
            break;

          case HttpEventType.Response:
            dto.id = event.body;
            this.fileId = event.body;
            this.isFileLoading = false;
            break;

        }
      });
  }

  public deleteFile(index: number) {
    event.stopImmediatePropagation();

    if (!this.fileId || !this.files[index]) {
      return;
    }

    this.isFileLoading = true;
    this._fileService.delete(this.files[index].id)
      .toPromise()
      .then(res => {
        this.files?.splice(index, 1);
        this.fileId = null;
        this.isFileLoading = false;
      }).catch(e => {
        this.isFileLoading = false;
      });
  }

  public import() {
    this.isProcessing = true;

    var f = this.form.value;
    var req = {
      fileId: this.fileId,
      supplierId: f.supplier?.id,
      supplierContactId: f.supplierContact?.id,
      offerTypeId: f.offerType?.id,
      currencyId: f.currency?.id,
      purchaseManagerId: f.purchaseManager?.id
    };

    this._offerService.offerImport(req)
      .result(this.form, (res: any) => {

        this.invalidProducts = false;
        this.isProcessing = false;
        this.deleteFile(0);

        this._message.ok({
          message: 'cards.import.messages.import_success'
        });

        this.dialogRef.close({ success: true });

      }, err => {

        this.invalidProducts = err.validationSummary == 'import.error.failed_to_create_import_data';
        this.validationResults = err.validationResults;

        this.isProcessing = false;

        if (err.validationSummary) {
          this._message.error({
            message: 'cards.' + err.validationSummary
          });
        }

      });
  }

  public viewErrors(type) {
    const validationData = this.validationResults;

    function getCsvData() {
      let arr = [];
      let columns = ['Row'];
      Object.keys(validationData).forEach(x => {
        const row = parseInt(x.match(/\d+/)[0], 10);
        const field = x.split('.')[1];
        if (!columns.includes(field)) {
          columns.push(field);
        }
        const values = validationData[x] || new Array<IErrorValue>();
        const value = values.map(x => x.value).join(", ");
        arr[row] = arr[row] || {};
        if (field && value) Object.assign(arr[row], { Row: row, [field]: value });
      })
      return {
        data: arr.filter(row => row !== null),
        columns
      };
    };

    const { data, columns } = getCsvData();
    this._exportService.exportToCsv(data, 'incorrectProducts', columns);
  }

}