<card [translationsPath]="translationsPath" [fullScreen]="state.isFullScreen">
  <div header-title-after *nonVisitor>
    <div
      class="button-group"
      *ngIf="appState.hasModule(LxmAppModule.ReceiveOffers)"
    >
      <!-- <lxm-button
        routerLink="/offers/receiving"
        label=".title_received_offers"
        [type]="kind == OfferKind.ReceivingOffer ? 'beige': 'transparent'"
        *ngIf="appState.hasRight([UserAction.ViewReceivedOffers])"
      >
      </lxm-button>
      <lxm-button
        routerLink="/offers/requests"
        label=".title_requested_offers"
        [type]="kind == OfferKind.OfferRequest ? 'beige': 'transparent'"
        *ngIf="appState.hasRight([UserAction.ManageReceivedOffers])"
      >
      </lxm-button>
      <lxm-button
        routerLink="/offers/sending"
        label=".title_sent_offers"
        [type]="kind == OfferKind.SupplierOffer ? 'beige': 'transparent'"
        *ngIf="appState.hasModule(LxmAppModule.SendOffers) && appState.hasRight([UserAction.ViewSupplierOffers])"
      >
      </lxm-button> -->
      <lxm-button
        routerLink="/offers/receiving"
        label=".title_received_offers"
        [class.active]="kind == OfferKind.ReceivingOffer"
        *ngIf="appState.hasRight([UserAction.ViewReceivedOffers])"
      >
      </lxm-button>
      <lxm-button
        routerLink="/offers/requests"
        label=".title_requested_offers"
        [class.active]="kind == OfferKind.OfferRequest"
        *ngIf="appState.hasRight([UserAction.ManageReceivedOffers])"
      >
      </lxm-button>
      <lxm-button
        routerLink="/offers/sending"
        label=".title_sent_offers"
        [class.active]="kind == OfferKind.SupplierOffer"
        *ngIf="appState.hasModule(LxmAppModule.SendOffers) && appState.hasRight([UserAction.ViewSupplierOffers])"
      >
      </lxm-button>
    </div>
  </div>

  <div header-actions [formGroup]="controls" *nonVisitor>
    <selected-offers
      class="mr15"
      [isSelected]="isOfferSelected"
      [(selection)]="selection"
      [(currentPageSelected)]="currentPageSelected"
      [kind]="kind"
    >
    </selected-offers>

    <ng-container *ngIf="kind == OfferKind.ReceivingOffer || kind == OfferKind.OfferRequest">
      <ng-container *ngIf="kind == OfferKind.ReceivingOffer">
        <div class="button-group">
          <!-- <lxm-button
            [type]="isListViewActive(OffersListViewType.All) ? 'beige' : 'transparent'"
            label="cards.offers.filters.all"
            (click)="setListView(OffersListViewType.All)"
          >
          </lxm-button>
          <lxm-button
            [type]="isListViewActive(OffersListViewType.Active) ? 'beige' : 'transparent'"
            label="cards.offers.filters.active"
            (click)="setListView(OffersListViewType.Active)"
          >
          </lxm-button>
          <lxm-button
            [type]="isListViewActive(OffersListViewType.Archive) ? 'beige' : 'transparent'"
            label="cards.offers.filters.archive"
            (click)="setListView(OffersListViewType.Archive)"
          >
          </lxm-button> -->
          <lxm-button
          [class.active]="isListViewActive(OffersListViewType.All)"
          label="cards.offers.filters.all"
          (click)="setListView(OffersListViewType.All)"
        >
        </lxm-button>
        <lxm-button
          [class.active]="isListViewActive(OffersListViewType.Active)"
          label="cards.offers.filters.active"
          (click)="setListView(OffersListViewType.Active)"
        >
        </lxm-button>
        <lxm-button
          [class.active]="isListViewActive(OffersListViewType.Archive)"
          label="cards.offers.filters.archive"
          (click)="setListView(OffersListViewType.Archive)"
        >
        </lxm-button>
        </div>
        <div class="flex flex-row">
          <lxm-button 
            type="beige" 
            class="ml15" 
            icon="imports" 
            label="Impordi"
            (click)="openImportDialog()">
          </lxm-button>
          <lxm-button
            type="beige"
            class="ml15"
            icon="excel"
            (click)="exportOffers()"
            [disabled]="selection.length < 1">
          </lxm-button>
        </div>
      </ng-container>

      <ng-container *ngIf="kind == OfferKind.OfferRequest">
        <div class="button-group">
          <lxm-button
          [class.active]="isListViewActive(OffersListViewType.All)"
          label="cards.offers.filters.all"
          (click)="setListView(OffersListViewType.All)">
          </lxm-button>
          <lxm-button
            [class.active]="isListViewActive(OffersListViewType.RequestDrafts)"
            label="cards.offers.filters.drafts"
            (click)="setListView(OffersListViewType.RequestDrafts)">
          </lxm-button>
          <lxm-button
            [class.active]="isListViewActive(OffersListViewType.SentRequests)"
            label="cards.offers.filters.sent"
            (click)="setListView(OffersListViewType.SentRequests)">
          </lxm-button>
        </div>

        <div>
          <lxm-button
            type="green"
            class="ml15"
            icon="plus-dark"
            label=".action.request_offer"
            routerLink="/offers/request"
            *requiredRights="[UserAction.ManageReceivedOffers]">
          </lxm-button>
        </div>

      </ng-container>

    </ng-container>


    <ng-container *ngIf="kind == OfferKind.SupplierOffer">
      <div class="button-group">
        <lxm-button
          [class.active]="isListViewActive(OffersListViewType.All)"
          label="cards.offers.filters.all"
          (click)="setListView(OffersListViewType.All)"
        >
        </lxm-button>
        <lxm-button
          [class.active]="isListViewActive(OffersListViewType.Saved)"
          label="cards.offers.filters.saved"
          (click)="setListView(OffersListViewType.Saved)"
        >
        </lxm-button>
        <lxm-button
          [class.active]="isListViewActive(OffersListViewType.Active)"
          label="cards.offers.filters.active"
          (click)="setListView(OffersListViewType.Active)"
        >
        </lxm-button>
        <lxm-button
          [class.active]="isListViewActive(OffersListViewType.Archive)"
          label="cards.offers.filters.archive"
          (click)="setListView(OffersListViewType.Archive)"
        >
        </lxm-button>
      </div>

      <lxm-button
        type="green"
        *requiredRights="[UserAction.ManageSupplierOffers]"
        class="ml15"
        icon="plus-dark"
        label=".action.create_offer"
        routerLink="/offers/new"
      >
      </lxm-button>

    </ng-container>
  </div>

  <div body class="sticky-table">
    <div class="sticky-table-body w-full overflow-x">
      <mat-table [dataSource]="dataSource" matSort [@.disabled]="true" [zoomValue]="state.zoom">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="offer_select" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div class="flex f-1 justify-center align-center">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="isAllSelected()"
                [indeterminate]="!isAllSelected()"
              >
              </mat-checkbox>
            </div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            stop-propagation
            (click)="$event ? toggleSelection(row) : null"
          >
            <div class="flex f-1 justify-center align-center">
              <mat-checkbox
                class="table-checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelection(row) : null"
                [checked]="isOfferSelected(row)"
                *ngIf="isAcceptedSelectionType(row.type?.id)"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="retailer">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{(translationsPath + '.table.retailer') | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              <type-indicator [type]="row.type"></type-indicator>
              {{row.retailer}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sentAt">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{(translationsPath + '.table.offer_sentAt') | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              {{row.sentAt | formDate}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="period">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{(translationsPath + '.table.period_date') | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              {{row.from | formDate}} - {{row.to | formDate}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="supplier">
          <mat-header-cell *matHeaderCellDef>
            <span mat-sort-header>
              {{(translationsPath + '.table.offer_supplier') | translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              <type-indicator [type]="row.type"></type-indicator>
              {{row.supplier}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="suppliers">
          <mat-header-cell *matHeaderCellDef>
            <span mat-sort-header>
              {{(translationsPath + '.table.offer_suppliers') | translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              <div class="comma-delimited-list">
                <span *ngFor="let supplier of row.suppliers">
                  {{supplier.name}}
                </span>
              </div>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="to">
          <mat-header-cell *matHeaderCellDef>
            <span mat-sort-header>
              {{(translationsPath + '.table.offer_valid_date') | translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex flex-row">
              {{row.to | formDate}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>
            <span mat-sort-header>
              {{(translationsPath + '.table.offer_name') | translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>{{row.name}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assignedTo">
          <mat-header-cell *matHeaderCellDef>
            <span>
              {{(translationsPath + '.table.offer_purchasing_manager') |
              translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="flex flex-col">
              <span *ngFor="let user of row.assignedTo">
                {{user.name || user.email}}
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="approved">
          <mat-header-cell *matHeaderCellDef>
            <span class="flex f-1 justify-start" mat-sort-header>
              {{(translationsPath + '.table.offer_approved_products') |
              translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex f-1 justify-start">
              {{row.confirmedProductCount}} / {{row.productCount}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="productsCount">
          <mat-header-cell *matHeaderCellDef>
            <span
              [innerHTML]="translationsPath + '.table.offer_products_count' | translate"
            ></span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{row.productCount}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>
            <span class="flex f-1 justify-end" mat-sort-header>
              {{(translationsPath + '.table.offer_status') | translate}}
            </span>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="flex f-1 justify-end">
              <status-indicator
                [status]="getReceivingOfferStatusProgress(row.status)"
                *ngIf="kind == OfferKind.ReceivingOffer"
                [description]="row.status | translateEnum : 'ReceivingOfferStatus'"
              ></status-indicator>

              <status-indicator
                [status]="getOfferRequestStatusProgress(row.status)"
                *ngIf="kind == OfferKind.OfferRequest"
                [description]="row.status | translateEnum : 'OfferRequestStatus'"
              ></status-indicator>

              <status-indicator
                [status]="getSendingOfferStatusProgress(row.status)"
                *ngIf="kind == OfferKind.SupplierOffer"
                [description]="row.status | translateEnum : 'SupplierOfferStatus'"
              ></status-indicator>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="table.columns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: table.columns;"
          class="table-row-hover"
          [class.new]="row.isNew"
          routerLink="/offers/{{row.id}}"
        ></mat-row>
      </mat-table>
    </div>

    <no-content
      *ngIf="!hasOffers"
      image="no-offers"
      heading="cards.offers.no_offers_heading"
      [text]="canManageSupplierOffers ? 'cards.offers.no_offers_supplier_text' : 'cards.offers.no_offers_retailer_text'">
    </no-content>

    <data-table-footer
      [localStorageKey]="LOCAL_STORAGE_KEY"
      [isLoading]="isLoading"
      (paginator)="hookPaginator($event)"
      [search]="search"
      (onSearch)="onSearch($event)"
      [(isFullScreen)]="state.isFullScreen" 
      (onToggleFullScreen)="state.isFullScreen = $event"
      [(zoom)]="state.zoom"
      (onLoad)="onLoad()">
    </data-table-footer>
  </div>
</card>

<a class="no-display" #download></a>
