import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IOffersRetailerListItem } from 'src/app/models';
import { Location } from '@angular/common';
import { ReceivingOfferStatus, RetailerOffersViewType, asObject, HttpMethod, OfferKind, UserAction, LxmAppModule, OfferRequestStatus, SupplierOfferStatus } from 'src/app/enum';
import { DataTableService, SignalRService, OfferService, AuthenticationService } from 'src/app/_services';
import { forkJoin, Subscription } from 'rxjs';
import { HubConnection } from '@microsoft/signalr';
import { OffersListBase} from '../offers-list-base';
import { ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { AppState } from 'src/app/state/app.state';
import { BuyerOffersListState, OffersListViewType } from '../offers-list.state';
import { map, mergeMap } from 'rxjs/operators';
import { OfferExportDialogComponent } from '../../offer/dialogs/export/export-offer.dialog';
import { LocalStorage, LxmDialog } from '../../../_helpers';
import { OfferImportDialogComponent } from '../../offer/dialogs/import/import-offer.dialog';

@Component({
  selector: 'offers-retailer-card',
  templateUrl: './offers-retailer.card.html',
  host: { 'class': 'data-table-card' },
  styleUrls: ['./offers-retailer.card.scss', '../offers-list-base.scss']
})
export class OffersRetailerCardComponent extends OffersListBase<IOffersRetailerListItem, any, BuyerOffersListState> implements OnInit, OnDestroy {

  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;

  public LxmAppModule = LxmAppModule;
  public UserAction = UserAction;
  public OfferKind = OfferKind;
  
  public dataUrl = 'offers/forRetailer';
  method = HttpMethod.Post;
  initialData;
  public translationsPath = 'cards.offers';

  @ViewChild('download') private _download: ElementRef;

  public controls = new FormGroup({
    archivedView: new FormControl(false)
  });

  public ReceivingOfferStatus = ReceivingOfferStatus;

  public viewType = asObject(OffersListViewType, OffersListViewType.All);

  public reqTableColumns = ['name', 'suppliers', 'productsCount', 'to', 'status'];
  public recevingOffersTableColumns = ['offer_select', 'name', 'supplier', 'approved', 'to', 'assignedTo', 'status'];
  public sendingOffersTableColumns = ['retailer', 'sentAt', 'period', 'name', 'approved', 'status'];

  public table = {
    columns: this.recevingOffersTableColumns
  };

  public canManageSupplierOffers = false;

  constructor(
    public appState: AppState,
    private _offerService: OfferService,
    route: ActivatedRoute,
    private _dialog: LxmDialog,
    dataTableService: DataTableService<IOffersRetailerListItem, any>,
    cd: ChangeDetectorRef,
    location: Location,
    public state: BuyerOffersListState,
    private _authService: AuthenticationService,
    private _signalR: SignalRService) {

    super(location, dataTableService, cd);
    this._state = state;

    this.canManageSupplierOffers = this._authService.hasRights([UserAction.ManageSupplierOffers]);
    
    this.initialData = route?.snapshot?.data?.initialData;
    this.kind = route?.snapshot?.data?.offerKind;

    if (this.kind == OfferKind.ReceivingOffer) {
      this.table.columns = this.recevingOffersTableColumns;
      this.dataUrl = 'offers/forRetailer';
      this.searchUrl = "/offers/receiving/search";
    }
    else if (this.kind == OfferKind.SupplierOffer) {
      this.table.columns = this.sendingOffersTableColumns;
      this.dataUrl = 'offers/forSupplier';
      this.searchUrl = "/offers/sending/search";
    } 
    else if (this.kind == OfferKind.OfferRequest) {
      this.table.columns = this.reqTableColumns;
      this.dataUrl = 'offers/requestedOffers';
      this.searchUrl = "/offers/requests/search";
    }

    this._signalRSubscription = this._signalR.commonHub
      .subscribe(x => {
        this._hubConnection = x;
        if (!x) {
          return;
        }
        x.on('offerHasChanged', data => {
          const row = this.dataSource.data.find(r => r.id === data.offerId);
          if (row) {
            row.isNew = data.read === false;
          }
        });
      });
  }

  protected getSortParam(sort: MatSort) {
    if (!sort || !sort.active || sort.direction === "") {
      return '';
    }

    let colId = sort.active;
    if (colId === 'approved') {
      colId = 'confirmedProductCount';
    }
    if (colId === 'period') {
      colId = 'from';
    }

    return colId + ' ' + sort.direction;
  }

  public getReceivingOfferStatusProgress(status: ReceivingOfferStatus) {
    return this._offerService.getReceivingOfferStatusProgress(status);
  }

  public getOfferRequestStatusProgress(status: OfferRequestStatus) {
    return this._offerService.getOfferRequestStatusProgress(status);
  }

  public getSendingOfferStatusProgress(status: SupplierOfferStatus) {
    return this._offerService.getSendingOfferStatusProgress(status);
  }

  public openImportDialog() {

    this._dialog.open(OfferImportDialogComponent, {
      resolve: () => {
        const resolvers: any = {
          formData: this._offerService.getOfferImportFormData()
        };

        return forkJoin(resolvers);
      }
    }, res => {
      if (!res) {
        return;
      }

      this.load();
    });

  }

  public exportOffers() {
    const offerIds = this.selection.map(x => x.id);

    this._dialog.open(OfferExportDialogComponent, {
      resolve: () =>
        this._offerService.exportMultipleOffersXls(offerIds)
          .pipe(
            mergeMap(async res => {
              const blob = await this._offerService.downloadXls(res.id).toPromise();
              return {
                fileName: res.fileName,
                blob: blob
              }
            })
          )
          .pipe(
            map(res => {
              const url = window.URL.createObjectURL(res.blob);

              const link = this._download.nativeElement;
              link.href = url;
              link.download = res.fileName;
              link.click();

              window.URL.revokeObjectURL(url);
            })
          )
    });

  }

  ngOnDestroy(): void {

    if (this._hubConnection) {
      this._hubConnection.off('offerHasChanged');
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }
}
