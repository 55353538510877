import { formatNumber } from '@angular/common';
import { locale as appLocale } from 'src/app/_services';

class NumberUtil {
    public toNumber(value: any, digits = null): number {
        if (value === null || value === undefined) {
            return value;
        }

        if (typeof value === "number") {
            return value;
        }

        if (typeof value !== "string") {
            // console.log("Cannot parse value: " + value)
            return value;
        }

        let parsedValue = parseFloat(value.trim().replace(/,/g, "."));

        if (digits) {
            return this.round(parsedValue, digits);
        }

        return parsedValue;
    }

    public isNumber(value: any): boolean {
        return typeof value === "number" && !isNaN(value);
    }

    public formatNumber(val, digitsInfo, locale = appLocale) {
        return formatNumber(this.toNumber(val), locale, digitsInfo);
    }

    public round(val, digits) {
      if (!val) {
        return val;
      }

      if (typeof val !== "number") { 
        val = this.toNumber(val);
      }

      const mul = Math.pow(10, digits);
      return Math.round(val * mul) / mul;
    }

    public applyVat(value, vat) {
        const numericValue = numberUtil.toNumber(value);
        return numberUtil.roundPrice(numericValue * (1 + vat / 100));
    }
  
    public excludeVat(value, vat) {
        const numericValue = numberUtil.toNumber(value);
        return numberUtil.roundPrice(numericValue / (1 + vat / 100));
    }

    public roundPrice(priceValue: number) {
        return Math.round((numberUtil.toNumber(priceValue) + Number.EPSILON) * 100) / 100;
    }
}

export const numberUtil = new NumberUtil();