import { Injectable } from "@angular/core";
import { Subject } from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import { ContentLanguage } from "src/app/_helpers";
import { IDisplayProduct } from "src/app/models/IDisplayProduct";
import { TranslatedValuePipe } from "src/app/pipes";

@Injectable({ providedIn: "root" })
export class ProductHeaderState {
  public readonly emptyDataModel = {
    id: null,
    fullName: null,
    images: [],
    brand: null,
    countryOfOrigin: null,
    ean13: null,
    supplierCode: null,
    internalCode: null,
    markingAndNutritionInfo: null,
    foodSuggestions: [],
    retailerAssortmentStatus: null,
    supplierAssortmentStatus: null
  };

  public dataSubject = new BehaviorSubject<any>(
    Object.assign({}, this.emptyDataModel)
  );

  constructor(private _translatedValue: TranslatedValuePipe) {}

  public get displayProductData(): IDisplayProduct {
    const {
      fullName,
      images,
      ean13,
      retailerAssortmentStatus,
      supplierAssortmentStatus,
      id
    } = this.dataSubject.value;
    return {
      id: id,
      ean: ean13?.value,
      name: this._translatedValue.transform(fullName),
      thumbnail: null,
      thumbnailUrl: images[0]?.thumbnailMediumUrl,
      thumbnailContentType: null,
      assortmentStatus: supplierAssortmentStatus
    };
  }

  public setData(data, reset = false) {
    let updatedData = reset
      ? Object.assign({}, this.emptyDataModel)
      : this.dataSubject.value;

    const {
      fullName,
      images,
      brand,
      countryOfOrigin,
      ean13,
      supplierCode,
      weighingGoodsCode,
      markingAndNutritionInfo,
      foodSuggestions,
      internalCode,
      retailerAssortmentStatus,
      supplierAssortmentStatus,
      awards,
      id,
      storageConditionId,
      alcoholContent,
      ethicalMarkingIds
    } = data || {};

    if (id !== undefined) {
      updatedData.id = id;
    }

    if (fullName !== undefined) {
      updatedData.fullName = fullName;
    }

    if (images !== undefined) {
      updatedData.images = images;
    }

    if (brand !== undefined) {
      updatedData.brand = brand;
    }

    if (countryOfOrigin !== undefined) {
      updatedData.countryOfOrigin = countryOfOrigin;
    }

    if (ean13 !== undefined) {
      updatedData.ean13 = ean13;
    }

    if (supplierCode !== undefined) {
      updatedData.supplierCode = supplierCode;
    }

    if (internalCode !== undefined) {
      updatedData.internalCode = internalCode;
    }

    if (weighingGoodsCode !== undefined) {
      updatedData.weighingGoodsCode = weighingGoodsCode;
    }

    if (markingAndNutritionInfo !== undefined) {
      updatedData.markingAndNutritionInfo = markingAndNutritionInfo;
    }

    if (awards !== undefined) {
      updatedData.awards = awards;
    }

    if (foodSuggestions) {
      updatedData.foodSuggestions = foodSuggestions;
    }

    if (retailerAssortmentStatus) {
      updatedData.retailerAssortmentStatus = retailerAssortmentStatus;
    }

    if (supplierAssortmentStatus) {
      updatedData.supplierAssortmentStatus = supplierAssortmentStatus;
    }

    if (storageConditionId) {
      updatedData.storageConditionId = storageConditionId;
    }

    if (alcoholContent) {
      updatedData.alcoholContent = alcoholContent;
    }

    if (ethicalMarkingIds) {
      updatedData.ethicalMarkingIds = ethicalMarkingIds;
    }

    this.dataSubject.next(updatedData);

    console.log("set data", updatedData);
  }

  public resetData() {
    const data = Object.assign({}, this.emptyDataModel);
    this.dataSubject.next(data);
  }
}
