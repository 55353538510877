<div class="reset-password-content" >

  <guest-language-selector></guest-language-selector>

  <div class="content">

    <div class="reset-container">

      <div class="reset-message-container">
        <card-message *ngIf="errors.length > 0" [messages]="errors" [absolute]="true"></card-message>
      </div>

      <div class="reset-card">

        <div class="reset-card-left">
          <img class="reset-card-img"
              src="../../assets/img/login/group-13.png"
              srcset="../../assets/img/login/group-13@2x.png 2x,
              ../../assets/img/login/group-13@3x.png 3x"
              draggable="false">
        </div>

        <div class="reset-card-right" [ngSwitch]="view">

          <ng-container *ngSwitchCase="passwordView">

            <div class="reset-password-info">
              <span class="reset-password-title">{{'login.new_password.title' | translate}}</span>
            </div>
            <form class="reset-tab-container mt30" [formGroup]="resetForm" (ngSubmit)="requestNewPassword()" novalidate>

              <lxm-input 
                inputClass="reset-input" 
                type="password" 
                for="password" 
                autocomplete="off" 
                placeholder="{{'login.new_password.placeholders.new_password' | translate}}">
                <validation-errors for="password"></validation-errors>
              </lxm-input>

              <lxm-input
                class="mt12"
                inputClass="reset-input" 
                type="password" 
                for="passwordConfirm"
                autocomplete="off" 
                placeholder="{{'login.new_password.placeholders.confirm_new_password' | translate}}">
                <validation-errors for="passwordConfirm"></validation-errors>
              </lxm-input>
              
              <lxm-button class="mt30" width="280px" type="primary" buttonType="submit" label="login.new_password.confirm" [loading]="newPasswordLoading"></lxm-button>
            </form>

            </ng-container>
          <ng-container *ngSwitchCase="successView">
            <div class="reset-password-info">
              <span class="reset-password-title">{{'login.new_password.title_success' | translate}}</span>
              <span class="reset-password-text mt12">
                {{'login.new_password.text_success' | translate}}
              </span>
            </div>

            <a class="forgot-password-link mt30" routerLink="/login">{{'login.new_password.back_to_log_in' | translate}}</a>
          </ng-container>

        </div>

      </div>

      <div class="reset-footer mb20">
        <copyright-block></copyright-block>
      </div>

    </div>
  </div>
</div>
