<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">
  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="!locked">
      <lxm-button *ngIf="!edit" class="ml15" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()">
      </lxm-button>
      <lxm-button *ngIf="edit" class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
      </lxm-button>
      <lxm-button *ngIf="edit" class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading"
        (click)="save()" buttonType="submit"></lxm-button>
    </ng-container>
    <ng-container *ngIf="locked">
      {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt |
      amTimeAgo }}
    </ng-container>
  </div>

  <div body>
    <card-section [ngSwitch]="showContent">
      <div class="row f-1" *ngSwitchCase="false">
        <no-content image="no-custom" text="cards.products.product_description_fields.no_content" [inline]="true" style="margin: 0;"></no-content>
      </div>
      <div class="row" *ngSwitchDefault>
        <div class="col-12 no-padding">
          <form-field  *ngIf="!edit ? (form.value.tags?.length > 0 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.tags" for="tags" description="cards.products.product_description_fields.label_description.tags">
            <lxm-select select for="tags"
              class="w100p"
              labelField="value"
              compareField="id"
              [readonly]="!edit"
              [options]="tagsOptions"
              [searchable]="true"
              [multiple]="true">
              <lxm-button 
                *requiredRights="[UserAction.ManageClvs]" 
                header
                label="action.add_new" 
                icon="plus-dark"
                (click)="openClassificatorValueDialog(ClvType.ProductTag, form.get('tags'), 'tagsOptions', true)">
              </lxm-button>
            </lxm-select>
            <validation-errors for="tags"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter1 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_1" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter1" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter1, form.get('parameter1').value) : null">
            <validation-errors for="parameter1"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter2 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_2" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter2" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter2, form.get('parameter2').value) : null">
            <validation-errors for="parameter2"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter3 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_3" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter3" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter3, form.get('parameter3').value) : null">
            <validation-errors for="parameter3"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter4 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_4" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter4" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter4, form.get('parameter4').value) : null">
            <validation-errors for="parameter4"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter5 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_5" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter5" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter5, form.get('parameter5').value) : null">
            <validation-errors for="parameter5"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter6 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_6" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter6" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter6, form.get('parameter6').value) : null">
            <validation-errors for="parameter6"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter7 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_7" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter7" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter7, form.get('parameter7').value) : null">
            <validation-errors for="parameter7"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter8 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_8" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter8" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter8, form.get('parameter8').value) : null">
            <validation-errors for="parameter8"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter9 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_9" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter9" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter9, form.get('parameter9').value) : null">
            <validation-errors for="parameter9"></validation-errors>
          </form-field>

          <form-field *ngIf="!edit ? (form.value.parameter10 ? true : false) : true" [readonly]="!edit" label="cards.products.product_description_fields.label.description_field_10" description="cards.products.product_description_fields.label_description.description_field"
            for="parameter10" type="textarea" maxlength="2500"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ExtraParameter10, form.get('parameter10').value) : null">
            <validation-errors for="parameter10"></validation-errors>
          </form-field>
        </div>
      </div>
    </card-section>
  </div>
</card-expanding>