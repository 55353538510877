import { Component, Input, HostBinding, ViewEncapsulation, Output, EventEmitter, SimpleChanges, SimpleChange } from "@angular/core";
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: "image-box",
  templateUrl: "./image-box.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./image-box.component.scss']
})
export class ImageBoxComponent {

  private readonly _fallbackImage = '/assets/img/icons/no-content/no-image.svg';

  @Input() public readonly = true;
  @Input() public openable = true;
  @Input() public images = [];
  @Input() public index = 0;
  @Input() public size: ImageBoxSize = ImageBoxSize.M;
  @Input() public imageFit = 'cover';
  @Input() public showImage = true;
  @Input() public showFallbackImage = true;

  @Output() public onDelete = new EventEmitter();

  @HostBinding('class') get boxSize() {
    return `size-${this.size}`;
  }

  @HostBinding('class.openable') public canOpen = false;

  public showOptionsList = false;
  public thumbnailSource: string;

  private _isFallbackImage = false;

  private _lightboxOptions = {
    centerVertically: true,
    enableTransition: false,
    fadeDuration: 0.3,
    resizeDuration: 0,
    showDownloadButton: false,
    wrapAround: false,
    containerElementResolver: () => document.getElementById("app-container")
  }

  constructor(private _lightbox: Lightbox) {}

  private _setCanOpen() {
    this.canOpen = this.openable && !this._isFallbackImage && (this.src ? true : false);
  }

  public get image() {
    if (!isNaN(this.index)) {
      return this.images[this.index]
    }
    return null;
  }

  public get src(): string {
    const image = this.thumbnailSource || this.image?.src || this.image?.url;
    if (!image && this.showFallbackImage) {
      this._isFallbackImage = true;
      return this._fallbackImage;
    }
    this._isFallbackImage = false;
    return image;
  }

  public get imageName() {
    return this.image?.name;
  }

  public get caption() {
    return this.image?.caption || '';
  }

  ngOnInit() {
    const { thumbnail, thumbnailContentType, thumbnailSmall } = this.image || {};
    if (thumbnail) {
      this.thumbnailSource = `data:${thumbnailContentType};base64,${thumbnail}`;
    }
    this._setCanOpen();
  }

  public toggleOptionsList(toSet = !this.showOptionsList) {
    this.showOptionsList = toSet;
  }

  public handleDelete() {
    this.onDelete.emit(this.image);
  }

  open(index: number = this.index): void {
    if (!this.canOpen) {
      return;
    }

    const lbImages = this.images.map(x => {
      const src = x.src || x.url;
      const caption = x.description || x.caption || x.comment;
      return {
        src: src,
        thumb: src,
        caption: caption
      }
    })

    this._lightbox.open(lbImages, index, this._lightboxOptions);
  }

  close(): void {
    this._lightbox.close();
  }

  public async downloadImage(imageSrc) {
    const image = await fetch(imageSrc)
    const imageBlog = await image.blob()
    const imageURL = URL.createObjectURL(imageBlog)
  
    const link = document.createElement('a')
    link.href = imageURL
    link.download = this.imageName || 'image';
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  ngOnChanges(changes: SimpleChanges) {
    this._setCanOpen();
  }

}

export enum ImageBoxSize {
  S = 1,
  M = 2,
  L = 3
}